import React, {useState} from "react";
import { Link } from "react-router-dom";
import { MenuItem } from "@mui/material/";
import MenuIcon from '@mui/icons-material/Menu';
import Menu from '@mui/material/Menu';
import Button from '@mui/material/Button';
import { useStore } from "../zustand/objects";
import { useAccountStore } from "../zustand/account"; 
import { Link as RouterLink } from 'react-router-dom';
import { ActionIcon } from '@mantine/core';

const MenuButton = ({pageName}) => {

 const { saveUserProject, wipeProjectState, unloadObjects } = useStore();
 const { logUserOut, isAuthUser, userStatus} = useAccountStore();

 const [hover, setHover] = useState(false)

  const [anchorEl, setAnchorEl] = useState(null);
  const open = Boolean(anchorEl);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null); 
  };

 
  async function logout() {
    logUserOut() 
  }
 
const handleLogoutClick = () => {
  logout().then(() => window.location.replace('https://mbxr.io/login'))

}

  const handleProjectClick = () => {
    wipeProjectState()
    unloadObjects()
  }

  return (
    <>
      <ActionIcon
        onClick={handleClick}
        variant="transparent"
        onPointerOver={() => setHover(true)}
        onPointerLeave={() => setHover(false)}
        bg={'none'}
        size={'sm'}
      >
        <MenuIcon fontSize={'small'} style={{ color: !hover ? "#424242" : "#868e96" }} />
      </ActionIcon>
      {/* </Button> */}
      <Menu
        id="basic-menu"
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        MenuListProps={{
          'aria-labelledby': 'basic-button',
        }} 
        style={{zIndex: 50000}}
      >
        {(pageName !== 'projects') && <MenuItem onClick={handleClose}><Button style={{backgroundColor: "white", color: "black"}}  size={"small"} onClick={() => handleProjectClick()} to={"/projects"} component={Link}> 
    Projects
    </Button> </MenuItem>}
    <MenuItem onClick={handleClose}><Button style={{backgroundColor: "white", color: "black"}}  size={"small"} >
   <a style={{textDecoration:"none", color:"black"}} target="_blank" href="https://www.matchboxxr.com/how-to">
    Guides</a>
    </Button></MenuItem>
   <MenuItem onClick={handleClose}><Button style={{backgroundColor: "white", color: "black"}}  size={"small"} to={"/templates"} component={Link}> 
    Template documents
    </Button> </MenuItem>
   {/* {userStatus === "loggedIn-active" && <MenuItem onClick={handleClose}><Button style={{backgroundColor: "white", color: "black"}}  size={"small"} to={"/templates"} component={Link}> 
    Templates
    </Button> </MenuItem>} */}
    <MenuItem onClick={handleClose}><a href="https://forms.gle/J4LkszzrjdimPoQX7" target="_blank" style={{textDecoration:"none", color:"black"}} ><Button style={{backgroundColor: "white", color: "black"}}  size={"small"}>
   {/* <a style={{textDecoration:"none", color:"black"}} target="_blank" href="https://matchboxxr.com/guides">Give feedback</a> */}
   {/* <a href="https://forms.gle/J4LkszzrjdimPoQX7" target="_blank" style={{textDecoration:"none", color:"black"}} > */}
    Give feedback
    </Button></a></MenuItem>
    {isAuthUser ? <MenuItem onClick={handleClose}>
      <Button style={{backgroundColor: "white", color: "black", width:"100%"}}  size={"small"} onClick={() => handleProjectClick()} to={"/account"} component={Link}> 
    Account Settings
    </Button> </MenuItem> :  <MenuItem onClick={handleClose}><Button  component={RouterLink} style={{backgroundColor: "white", color: "black"}}  size={"small"}  to={"/login"} >
    Login
    </Button></MenuItem>}
    {isAuthUser && <MenuItem onClick={handleClose}><Button style={{backgroundColor: "white", color: "black"}}  size={"small"}  onClick={handleLogoutClick} >
    Logout</Button></MenuItem>}
    {/* <MenuItem onClick={handleClose}><LogoutButton/></MenuItem> */}
    
      </Menu>

  </>  
  );
};

export default MenuButton;
