// function to generate random strings
// declare all characters
const characters = 'abcdefghijklmnopqrstuvwxyz0123456789';
// generate the string for a projectKey
const generateUniqueString = (length) => {
  let result = ' ';
  const charactersLength = characters.length;
  for (let i = 0; i < length; i++) {
    result += characters.charAt(Math.floor(Math.random() * charactersLength));
  }

  return result;
}

// old api endpoint

// SIGN UP A NEW USER
export const sendNewUser = (
  data,
  url = 'https://x1hz-knoc-qcpv.e2.xano.io/api:aIaNUgG7/auth/signup'
) => {
  return fetch(url, {
    method: 'POST',
    headers: {
      'Accept': 'application/json',
      'Content-Type': 'application/json',
    },
    body: JSON.stringify({
      id: Math.random().toString(16).slice(2),
      created_at: data.created_at,
      firstName: data.firstName,
      lastName: data.lastName,
      email: data.email,
      password: data.password,
      projects: ['']
    })
  })
    .then((response) => response.json())
    .catch((error) => {
      console.error("Error:", error);
    });
};

// DELETE A USER AND STRIPE/AUTH0/GOOGLE/FIGMA INTS AND ASSOCIATED PROJECTS/IMAGES/MODELS/FIGMAS
export const deleteUserAndSubs = (
) => {
  return fetch('https://x1hz-knoc-qcpv.e2.xano.io/api:aIaNUgG7/user', {
    method: 'DELETE',
    headers: {
      "content-type": "application/json",
      "Authorization": `Bearer ${localStorage.getItem('user')}`
    }
  })
    .then((response) => response.json())
    .then((data) => {
      return data
    })
    .catch((error) => {
      console.error("Error:", error);

    });
}

// LOGIN AN EXISTING USER
export const sendUserLogin = (
  data,
  url = 'https://x1hz-knoc-qcpv.e2.xano.io/api:aIaNUgG7/auth/login'
) => {
  return fetch(url, {
    method: 'POST',
    headers: {
      'Accept': 'application/json',
      'Content-Type': 'application/json',
    },
    body: JSON.stringify({
      email: data.email,
      password: data.password
    })
  })
    .then((response) => response.json())
    .then((data) => {return data})
    .catch((error) => {
      console.error("Error:", error);
    });
}
// GET AUTH0 CONVERTED TOKEN FROM XANO 
export const getAuth0Token = (data
  ) => {           
    return fetch('https://x1hz-knoc-qcpv.e2.xano.io/api:Ys-isoaj/oauth/auth0/continue?code=' + data.code + '&redirect_uri=' + data.redirect_uri, {
      method: 'GET',
      headers: {
        "content-type": "application/json"
        // "Authorization": `Bearer ${localStorage.getItem("user")}`
      }
      // body: JSON.stringify({
      //   code: data.code,
      //   redirect_uri: data.uri
      // })
    })
      .then((response) =>
        response.json()
      )
      .then((data) => {
        // console.log(data)
        // localStorage.setItem('user',data.token)
        return data
      })
      .catch((error) => {
        return error
      });
  }
// GET AUTH0 LOGIN URL
export const getAuth0URL = (data
  ) => {
    return fetch('https://x1hz-knoc-qcpv.e2.xano.io/api:Ys-isoaj/oauth/auth0/init' +'?redirect_uri='+data, {
      method: 'GET',
      headers: {
        "content-type": "application/json"
        // "Authorization": `Bearer ${localStorage.getItem("user")}`
      }
      // body: JSON.stringify({
      //   code: data.code,
      //   redirect_uri: data.uri
      // })
    })
      .then((response) =>
        response.json()
      )
      .then((data) => {
        return data
      })
      .catch((error) => {
        return error
      });
  }

// VALIDATE USER AUTH TOKEN
export const checkAuthToken = () => {
  return fetch('https://x1hz-knoc-qcpv.e2.xano.io/api:aIaNUgG7/auth/me', {
    method: 'GET',
    headers: {
      "content-type": "application/json",
      "Authorization": `Bearer ${localStorage.getItem("user")}`
    },
  })
    .then((response) =>
      response.json()
    )
    .then((data) => {
      return data
    })
    .catch((error) => {
      
      return error
    });
}

// VALIDATE USER AUTH TOKEN AND SUBSCRIPTION
export const checkAuthTokenAndSubscription = () => {
  return fetch('https://x1hz-knoc-qcpv.e2.xano.io/api:UQuTJ3vx/auth/subscription', {
    method: 'GET',
    headers: {
      "content-type": "application/json",
      "Authorization": `Bearer ${localStorage.getItem("user")}`
    },
  })
    .then((response) =>
      response.json()
    )
    .then((data) => {
      return data
    })
    .catch((error) => {
      
      return error
    });
}

// CREATE A NEW PROJECT
export const createNewProjectRecord = (
  data
) => {
  return fetch('https://x1hz-knoc-qcpv.e2.xano.io/api:aIaNUgG7/project_with_key', {
    method: 'POST',
    headers: {
      "content-type": "application/json",
      "Authorization": `Bearer ${localStorage.getItem('user')}`
    },
    body: JSON.stringify({
      projectName: data.name.name,
      // projectUserName: data.projectUserName,
      // projectUserActivity: data.projectUserActivity,
      // projectUserOutcome: data.projectUserOutcome,
      artboards: data.artboards,
      storyboardFields: data.storyboardFields,
      floorColour: data.floorColour
    })
  })
}


// GET ALL A USER'S PROJECTS
export const getUserProjectsAPI = (
  data
) => {
  return fetch('https://x1hz-knoc-qcpv.e2.xano.io/api:aIaNUgG7/project', {
    method: 'GET',
    headers: {
      "content-type": "application/json",
      "Authorization": `Bearer ${data}`
    },
  })
    .then((response) => response.json())
    .then((data) => {
      return data
    })
    .catch((error) => {
      console.error("Error:", error);

    });
}



// GET ALL PROJECTS A USER IS A GUEST TO
export const getUserAccount = (
  data
) => {
  return fetch('https://x1hz-knoc-qcpv.e2.xano.io/api:aIaNUgG7/user/', {
    method: 'GET',
    headers: {
      "content-type": "application/json",
      "Authorization": `Bearer ${localStorage.getItem('user')}`
    },
  })
    .then((response) => response.json())
    .then((data) => {
      return data
    })
    .catch((error) => {
      console.error("Error:", error);

    });
}



// GET ALL PROJECTS A USER IS A GUEST TO
export const getGuestProjectsAPI = (
  data
) => {
  return fetch('https://x1hz-knoc-qcpv.e2.xano.io/api:aIaNUgG7/user/sharedProjects', {
    method: 'GET',
    headers: {
      "content-type": "application/json",
      "Authorization": `Bearer ${localStorage.getItem('user')}`
    },
  })
    .then((response) => response.json())
    .then((data) => {
      return data
    })
    .catch((error) => {
      console.error("Error:", error);

    });
}

// ADD NEW USER' GUEST PROJECTS  (is link correct?)
export const setGuestProjectsAPI = (
  data
) => {
  return fetch('https://x1hz-knoc-qcpv.e2.xano.io/api:aIaNUgG7/user/' + data.userID, {
    method: 'POST',
    headers: {
      "content-type": "application/json",
      "Authorization": `Bearer ${localStorage.getItem('user')}`
    },
    body: JSON.stringify({
      userEmail: data.userEmail,
      projectKey: data.projectKey
    })
  })
    .then((response) => response.json())
    .then((data) => {
      return data
    })
    .catch((error) => {
      console.error("Error:", error);

    });
}
// REMOVE A USER GUEST PROJECT
export const deleteGuestProjectsAPI = (
  data
) => {
  return fetch('https://x1hz-knoc-qcpv.e2.xano.io/api:aIaNUgG7/user/guestProject/delete', {
    method: 'DELETE',
    headers: {
      "content-type": "application/json",
      "Authorization": `Bearer ${localStorage.getItem('user')}`
    },
    body: JSON.stringify({
      userEmail: data.userEmail,
      projectKey: data.projectKey
    })
  })
    .then((response) => response.json())
    .then((data) => {
      return data
    })
    .catch((error) => {
      console.error("Error:", error);

    });
}


// GET SPECIFIC PROJECT
export const getSpecificUserProjectAPI = (
  data
) => {
  return fetch('https://x1hz-knoc-qcpv.e2.xano.io/api:aIaNUgG7/project/' + data, {
    method: 'GET',
    headers: {
      "content-type": "application/json",
      "Authorization": `Bearer ${localStorage.getItem('user')}`
      // "Authorization": `Bearer ${localStorage.getItem('user')}`
    },
  })
    .then((response) => response.json())
    .then((data) => {
      if (data === 404) {
        window.location.href = "/404"
      return } else {
      return data }
    })
    .catch((error) => {
      console.error("Error:", error);
      if (error.response.status == 404) {
        window.location.href = "/404";
        return;
      }
    });
}

// GET SPECIFIC PROJECT SIGNEDOUT
export const getSpecificUserProjectGuestAPI = (
  data
) => {
  return fetch('https://x1hz-knoc-qcpv.e2.xano.io/api:aIaNUgG7/projectGuest/' + data, {
    method: 'GET',
    headers: {
      "content-type": "application/json",
      // "Authorization": `Bearer ${localStorage.getItem('user')}`
    },
  })
    .then((response) => response.json())
    .then((data) => {
      return data
    })
    .catch((error) => {
      console.error("Error:", error);
      if (error.response.status == 404) {
        window.location.href = "/404";
        return;
      }
    });
}

// GET PROJECT GUEST PREVIEW STATE
export const getSpecificUserProjectGuestAccessAPI = (
  data
) => {
  return fetch('https://x1hz-knoc-qcpv.e2.xano.io/api:aIaNUgG7/projectAccessBool/' + data, {
    method: 'GET',
    headers: {
      "content-type": "application/json",
      // "Authorization": `Bearer ${localStorage.getItem('user')}`
    },
  })
    .then((response) => response.json())
    .then((data) => {
      if (data === 404) {
        window.location.href = "/404"
      return } else {
      return data }
    })
    .catch((error) => {
      console.error("Error:", error);
      if (error.response.status == 404) {
        window.location.href = "/404";
        return;
      }
    });
}

// SET PROJECT GUEST PREVIEW STATE
export const setSpecificUserProjectGuestAccessAPI = (
  data
) => {
  return fetch('https://x1hz-knoc-qcpv.e2.xano.io/api:aIaNUgG7/project/' + data.projectKey + '/setGuestAccess', {
    method: 'POST',
    headers: {
      "content-type": "application/json",
      "Authorization": `Bearer ${localStorage.getItem('user')}`
    },
    body: JSON.stringify({
      projectKey: data.projectKey,
      loggedOutGuestAccess: data.loggedOutGuestAccess
    })
  })
    .then((response) => response.json())
    .then((data) => {
      return data
    })
    .catch((error) => {
      console.error("Error:", error);
      if (error.response.status == 404) {
        window.location.href = "/404";
        return;
      }
    });
}

// GET PROJECT GUESTS
export const getProjectGuestsAPI = (
  data
) => {
  return fetch('https://x1hz-knoc-qcpv.e2.xano.io/api:aIaNUgG7/project/' + data + '/guests', {
    method: 'GET',
    headers: {
      "content-type": "application/json",
      "Authorization": `Bearer ${localStorage.getItem('user')}`
    },
  })
    .then((response) => response.json())
    .then((data) => {
      return data
    })
    .catch((error) => {
      console.error("Error:", error);
      if (error.response.status == 404) {
        window.location.href = "/404";
        return;
      }
    });
}

// DELETE SPECIFIC PROJECT
export const deleteSpecificUserProjectAPI = (
  data
) => {
  return fetch('https://x1hz-knoc-qcpv.e2.xano.io/api:aIaNUgG7/project/' + data, {
    method: 'DELETE',
    headers: {
      "content-type": "application/json",
      "Authorization": `Bearer ${localStorage.getItem('user')}`
    },
  })
    .then((response) => response.json())
    .then((data) => {
      return data
    })
    .catch((error) => {
      console.error("Error:", error);
    });
}

// SAVE PROJECT
export const saveUserProjectAPI = (
  data
) => {
  return fetch('https://x1hz-knoc-qcpv.e2.xano.io/api:aIaNUgG7/project/' + data.projectID, {
    method: 'POST',
    headers: {
      "content-type": "application/json",
      "Authorization": `Bearer ${localStorage.getItem('user')}`
    },
    body: JSON.stringify({
      project_id: data.projectID,
      projectName: data.projectName,
      projectUserName: data.projectUserName,
      projectUserActivity: data.projectUserActivity,
      projectUserOutcome: data.projectUserOutcome,
      artboards: data.artboards,
      storyboardFields: data.storyboardFields,
      floorColour: data.floorColour,
      projectKey: data.projectID,
    })

  })
    .then((response) => response.json())
    .then((data) => {
      return data
    })
    .catch((error) => {
      console.error("Error:", error);
    });
}

// UPDATE OBJECT FIELD
export const saveObjectUpdate = (
  data
) => {
  return fetch('https://x1hz-knoc-qcpv.e2.xano.io/api:aIaNUgG7/object/update', {
    method: 'POST',
    headers: {
      "content-type": "application/json",
      "Authorization": `Bearer ${localStorage.getItem('user')}`
    },
    body: JSON.stringify({
      projectKey: data.projectKey,
      artboard: data.artboard,
      objectID: data.objectID,
      field: data.field,
      data: data.data
    })

  })
    .then((response) => response.json())
    .then((data) => {
      return data
    })
    .catch((error) => {
      console.error("Error:", error);
    });
}

// ADD OBJECT TO ARTBOARD
export const saveObjectAdd = (
  data
) => {
  return fetch('https://x1hz-knoc-qcpv.e2.xano.io/api:aIaNUgG7/object/add', {
    method: 'POST',
    headers: {
      "content-type": "application/json",
      "Authorization": `Bearer ${localStorage.getItem('user')}`
    },
    body: JSON.stringify({
      projectKey: data.projectKey,
      artboard: data.artboard,
      object: data.object
    })

  })
    .then((response) => response.json())
    .then((data) => {
      return data 
    })
    .catch((error) => {
      console.error("Error:", error);
    });
}

// DELETE OBJECT FROM ARTBOARD
export const saveObjectDelete = (
  data
) => {
  return fetch('https://x1hz-knoc-qcpv.e2.xano.io/api:aIaNUgG7/object/delete', {
    method: 'POST',
    headers: {
      "content-type": "application/json",
      "Authorization": `Bearer ${localStorage.getItem('user')}`
    },
    body: JSON.stringify({
      projectKey: data.projectKey,
      artboard: data.artboard,
      objectID: data.objectID
    })

  })
    .then((response) => response.json())
    .then((data) => {
      return data
    })
    .catch((error) => {
      console.error("Error:", error);
    });
}

// SAVE PROJECT GUESTS ONLY
export const saveUserGuestsAPI = (
  data
) => {
  return fetch('https://x1hz-knoc-qcpv.e2.xano.io/api:aIaNUgG7/project/' + data.projectKey + '/projectGuests', {
    method: 'POST',
    headers: {
      "content-type": "application/json",
      "Authorization": `Bearer ${localStorage.getItem('user')}`
    },
    body: JSON.stringify({
      projectKey: data.projectKey,
      projectGuests: data.projectGuests
    })

  })
    .then((response) => response.json())
    .then((data) => {
      return data
    })
    .catch((error) => {
      console.error("Error:", error);
    });
}




// SAVE PROJECT ARTBOARDS ONLY
export const saveUserArtboardsAPI = (
  data
) => {
  return fetch('https://x1hz-knoc-qcpv.e2.xano.io/api:aIaNUgG7/project/' + data.projectKey, {
    method: 'POST',
    headers: {
      "content-type": "application/json",
      "Authorization": `Bearer ${localStorage.getItem('user')}`
    },
    body: JSON.stringify({
      project_id: data.projectID,
      artboards: data.artboards,
      projectKey: data.projectKey,
    })

  })
    .then((response) => response.json())
    .then((data) => {
      return data
    })
    .catch((error) => {
      console.error("Error:", error);
    });
}

// SAVE PROJECT SCREENSHOTS ONLY
export const saveUserScreenshotsAPI = (
  data
) => {
  return fetch('https://x1hz-knoc-qcpv.e2.xano.io/api:aIaNUgG7/projectScreenshot/' + data.projectKey, {
    method: 'POST',
    headers: {
      "content-type": "application/json",
      "Authorization": `Bearer ${localStorage.getItem('user')}`
    },
    body: JSON.stringify({
      projectKey: data.projectKey,
      screenshots: data.screenshots
    })
  })
    .then((response) => response.json())
    .then((data) => {
      return data
    })
    .catch((error) => {
      return error
    });
}


 
//GET SPECIFIC AI MODEL VIA ID
export const getSpecificAIModel = (
  data
) => {
  // return fetch('https://x1hz-knoc-qcpv.e2.xano.io/api:a_vAWL9X/ai?id=' + data, {
  return fetch('https://x1hz-knoc-qcpv.e2.xano.io/api:a_vAWL9X/ai_model/single/?id=' + data, {
    method: 'GET',
    headers: {
      "content-type": "application/json",
      "Authorization": `Bearer ${localStorage.getItem('user')}`
    }
  })
    .then((response) => response.json())
    .then((data) => {
      return data
    })
    .catch((error) => {
      console.error("Error:", error);
    });
}

// GET USER'S AI MODELS
export const getUserAIModels = (
  data
) => {
  return fetch('https://x1hz-knoc-qcpv.e2.xano.io/api:a_vAWL9X/ai_model', {
    method: 'GET',
    headers: {
      "content-type": "application/json",
      "Authorization": `Bearer ${localStorage.getItem('user')}`
    },
  })
    .then((response) => response.json())
    .then((data) => {
      return data
    })
    .catch((error) => {
      console.error("Error:", error);
    });
}
// GET ALL AI MODELS
export const getAllAIModels = (
  data
) => {
  return fetch('https://x1hz-knoc-qcpv.e2.xano.io/api:a_vAWL9X/ai_model/all', {
    method: 'GET',
    headers: {
      "content-type": "application/json",
      "Authorization": `Bearer ${localStorage.getItem('user')}`
    },
  })
    .then((response) => response.json())
    .then((data) => {
      return data
    })
    .catch((error) => {
      console.error("Error:", error);
    });
}
// GET USER'S AI QUOTA
export const getUserAIQuota = (
  data
) => {
  return fetch('https://x1hz-knoc-qcpv.e2.xano.io/api:aIaNUgG7/user/ai_quota', {
    method: 'GET',
    headers: {
      "content-type": "application/json",
      "Authorization": `Bearer ${localStorage.getItem('user')}`
    },
  })
    .then((response) => response.json())
    .then((data) => {
      return data
    })
    .catch((error) => {
      console.error("Error:", error);
    });
}

// SEND MODEL PROMPT FOR OPENAI
export const sendAIModelPrompt = (
  data 
) => {
  return fetch('https://x1hz-knoc-qcpv.e2.xano.io/api:a_vAWL9X/ai_model/sloyd', {
    method: 'POST',
    headers: {
      "content-type": "application/json",
      "Authorization": `Bearer ${localStorage.getItem('user')}`
    },
    body: JSON.stringify({
      prompt: data
    })

  })
    .then((response) => response.json())
    .then((data) => {
      return data
    })
    .catch((error) => {
      console.error("Error:", error);
    });
}
// OLD MESHY SEND MODEL PROMPT FOR OPENAI
export const oldSendAIModelPrompt = (
  data
) => {
  return fetch('https://x1hz-knoc-qcpv.e2.xano.io/api:a_vAWL9X/ai_model', {
    method: 'POST',
    headers: {
      "content-type": "application/json",
      "Authorization": `Bearer ${localStorage.getItem('user')}`
    },
    body: JSON.stringify({
      prompt: data
    })

  })
    .then((response) => response.json())
    .then((data) => {
      return data
    })
    .catch((error) => {
      console.error("Error:", error);
    });
}

// DELETE SPECIFIC AI MODEL
export const deleteSpecificAIModel = (
  data
) => {
  return fetch('https://x1hz-knoc-qcpv.e2.xano.io/api:a_vAWL9X/ai_model/' + data, {
    method: 'DELETE',
    headers: {
      "content-type": "application/json",
      "Authorization": `Bearer ${localStorage.getItem('user')}`
    }
  })
    .then((response) => response.json())
    .then((data) => {
      return data
    })
    .catch((error) => {
      console.error("Error:", error);
    });
}





//GET SPECIFIC UPLOADED MODEL VIA ID
export const getSpecificUploadModel = (
  data
) => {
  return fetch('https://x1hz-knoc-qcpv.e2.xano.io/api:aIaNUgG7/model/' + data, {
    method: 'GET',
    headers: {
      "content-type": "application/json",
      "Authorization": `Bearer ${localStorage.getItem('user')}`
    }
  })
    .then((response) => response.json())
    .then((data) => {
      return data
    })
    .catch((error) => {
      console.error("Error:", error);
    });
}

// GET USER'S AI PERMISSIONS
export const getUserHasAI = (
  data
) => {
  return fetch('https://x1hz-knoc-qcpv.e2.xano.io/api:aIaNUgG7/user/', {
    method: 'GET',
    headers: {
      "content-type": "application/json",
      "Authorization": `Bearer ${localStorage.getItem('user')}`
    },
  })
    .then((response) => response.json())
    .then((data) => {
      return data.hasAI
    })
    .catch((error) => {
      console.error("Error:", error);
    });
}

// POST FIGMA KEY TO XANO TO STORE (LASTS 90 DAYS)
export const postFigmaPersonalKey = (
  data
) => {
  return fetch("https://x1hz-knoc-qcpv.e2.xano.io/api:aIaNUgG7/editFigmaKey",
  {
    method: 'POST',
    headers: {
      "content-type": "application/json",
      "Authorization": `Bearer ${localStorage.getItem('user')}`
    },
    body: JSON.stringify({
      figmaKey: data.figmaKey
    })
  })
}

// POST FIGMA KEY TO XANO TO STORE (LASTS 90 DAYS)
export const postMiroPersonalKey = (
  data
) => {
  return fetch("https://x1hz-knoc-qcpv.e2.xano.io/api:aIaNUgG7/editMiroKey",
  {
    method: 'POST',
    headers: {
      "content-type": "application/json",
      "Authorization": `Bearer ${localStorage.getItem('user')}`
    },
    body: JSON.stringify({
      miroKey: data.miroKey
    })
  })
}
 
// POST FIGMA AUTH DATA FOR PERMISSION TOKEN & SEND TO XANO
export const postFigmaAuthData = (
  data
) => {
  return fetch(
    "https://x1hz-knoc-qcpv.e2.xano.io/api:aIaNUgG7/figmaGetAccessToken"
  , {
    method: 'POST',
    headers: {
      "content-type": "application/json",
      "Authorization": `Bearer ${localStorage.getItem('user')}`
    },
    body: JSON.stringify({
      code: data.code
    })

  })
  .then((response) => response.json())
    .catch((error) => {
      console.error("Error:", error);
    });
}
// POST MIRO AUTH DATA FOR PERMISSION TOKEN & SEND TO XANO
export const postMiroAuthData = (
  data
) => {
  return fetch(
    "https://api.miro.com/v1/oauth/token" 
    + "&grant_type=" + data.grantType
    + "?client_id=" + data.clientID
    + "&client_secret=" + data.clientSecret
    + "&code=" + data.code
    + "&redirect_uri=" + data.callbackURI
  , {
    method: 'POST',
    headers: {
      'Origin': 'https://mbxr.io',
      'Content-Type': 'application/x-www-form-urlencoded',
      'Accept': 'application/json'
    }

  })
  .then((response) => response.json())
  .then((data) => {
    postMiroPersonalKey({miroKey: data.access_token, user: localStorage.getItem('user')})
  })
    .catch((error) => {
      console.error("Error:", error);
    });
}


// ADD FIGMA FRAME TO XANO FROM LINK
// NEED CONDITIONAL TO MAKE SURE WE DON'T ADD IT TWICE IF IT EXISTS, JUST EDIT EXISTING
export const addFigmaFrameFromLink = (
  data
) => {
  return fetch(`https://x1hz-knoc-qcpv.e2.xano.io/api:aIaNUgG7/figmaframes`, {
    method: 'POST',
    headers: {
      "content-type": "application/json",
      "Authorization": `Bearer ${localStorage.getItem('user')}`
    },
    body: JSON.stringify({
      frameID: data.frameID,
      fileID: data.fileID
    })
  })
    .then((response) => response.json())
    .catch((error) => {
      console.error("Error:", error);
    });
}

// UPDATE FIGMA TO LATEST THUMBNAIL
export const updateFigmaFromID = (
  data
) => {
  return fetch(`https://x1hz-knoc-qcpv.e2.xano.io/api:aIaNUgG7/figmaframes/${data}`, {
    method: 'POST',
    headers: {
      "content-type": "application/json",
      "Authorization": `Bearer ${localStorage.getItem('user')}`
    }
  })
    .then((response) => response.json())
    .catch((error) => {
      console.error("Error:", error);
    });
}

// GET FIGMA FRAME FROM IMAGE LINK
export const getFigmaFrameFromLink = (
  data
) => {
  return fetch(`https://api.figma.com/v1/images/sO1XQpnEnmQvYLzNFozPPE?ids=208-556`, {
    method: 'GET',
    headers: {
      "content-type": "application/json",
      "Authorization": `Bearer ${localStorage.getItem('figmaAuthToken')}`
    },
  })
    .then((response) => response.json())
    .then((data) => {
      // console.log(data.images)
      localStorage.setItem('figmaImage', data.images['208:556'])
    })
    .catch((error) => {
      console.error("Error:", error);
    });
}

// GET FIGMA FRAME FROM ID
export const getFigmaFrameFromID = (
  data
) => {
  return fetch(`https://x1hz-knoc-qcpv.e2.xano.io/api:aIaNUgG7/figmaframes/${data}`, {
    method: 'GET',
    headers: {
      "content-type": "application/json",
      "Authorization": `Bearer ${localStorage.getItem('user')}`
    },
  })
    .then((response) => response.json())
    .catch((error) => {
      console.error("Error:", error);
    });
}

// GET ALL USER'S FIGMA FRAME URLS FROM TABLE
export const getUserFigmaFramesAPI = () => {
  return fetch('https://x1hz-knoc-qcpv.e2.xano.io/api:aIaNUgG7/figmaframes', {
    method: 'GET',
    headers: {
      "content-type": "application/json",
      "Authorization": `Bearer ${localStorage.getItem('user')}`
    },
  })
    .then((response) => response.json())
    .then((data) => {
      return data
    })
    .catch((error) => {
      console.error("Error:", error);
    });
}

// GET USER'S TEAM'S PROJECTS 
export const getTeamFigmaProjects = (
  data
) => {
  return fetch('https://x1hz-knoc-qcpv.e2.xano.io/api:aIaNUgG7/figmaframes', {
    method: 'GET',
    headers: {
      "content-type": "application/json",
      "Authorization": `Bearer ${localStorage.getItem('user')}`
    },
  }) 
    .then((response) => response.json())
    .then((data) => {
      return data
    })
    .catch((error) => {
      console.error("Error:", error);
    });
}
// CHECK IF VALID FIGMA KEY EXITS
export const checkForValidFigmaKey = (
  data
) => {
  return fetch('https://x1hz-knoc-qcpv.e2.xano.io/api:aIaNUgG7/user/hasFigmaKey', {
    method: 'GET',
    headers: {
      "content-type": "application/json",
      "Authorization": `Bearer ${localStorage.getItem('user')}`
    },
  })
    .then((response) => response.json())
    .then((data) => {
      return data
    })
    .catch((error) => {
      console.error("Error:", error);
    });
}


//DELETE FIGMA FRAME
export const deleteFigmaFrameFromAccount = (
  data
) => {
  return fetch('https://x1hz-knoc-qcpv.e2.xano.io/api:aIaNUgG7/figmaframes/' + data, {
    method: 'DELETE',
    headers: {
      "content-type": "application/json",
      "Authorization": `Bearer ${localStorage.getItem('user')}`
    }
  })
    .then((response) => response.json())
    .then((data) => {
      return data
    })
    .catch((error) => {
      console.error("Error:", error);

    });
}
//DELETE FIGMA SYNC
export const deleteFigmaSync = (
  data
) => {
  return fetch('https://x1hz-knoc-qcpv.e2.xano.io/api:aIaNUgG7/figmasync', {
    method: 'DELETE',
    headers: {
      "content-type": "application/json",
      "Authorization": `Bearer ${localStorage.getItem('user')}`
    }
  })
    .then((response) => response.json())
    .then((data) => {
      return data
    })
    .catch((error) => {
      console.error("Error:", error);

    });
}


// GET INITIAL FIGMA AUTH URL
export const getFigmaAuthURL = (
  data
) => {
  return fetch(`https://x1hz-knoc-qcpv.e2.xano.io/api:aIaNUgG7/getFigmaAuthURL?rando=${data}`, {
    method: 'GET',
    headers: {
      "content-type": "application/json",
      "Authorization": `Bearer ${localStorage.getItem('user')}`
    }
  })
    .then((response) => response.json())
    .then((data) => {
      return data
    })
    .catch((error) => {
      console.error("Error:", error);
    });
}



// CHECK FOR MIRO ENTRY
export const getMiroSyncEntry = (
  data
) => {
  return fetch('https://x1hz-knoc-qcpv.e2.xano.io/api:aIaNUgG7/mirosync/haveEntry', {
    method: 'GET',
    headers: {
      "content-type": "application/json",
      "Authorization": `Bearer ${localStorage.getItem('user')}`
    }
  })
    .then((response) => response.json())
    .then((data) => {
      return data
    })
    .catch((error) => {
      console.error("Error:", error);
    });
}




// REMOVE A USER miro integration
export const deleteUserMiroEntry = (
  data
) => {
  return fetch('https://x1hz-knoc-qcpv.e2.xano.io/api:aIaNUgG7/mirosync/deleteEntry', {
    method: 'DELETE',
    headers: {
      "content-type": "application/json",
      "Authorization": `Bearer ${localStorage.getItem('user')}`
    }
  })
    .then((response) => response.json())
    .then((data) => {
      return data
    })
    .catch((error) => {
      console.error("Error:", error);

    });
}


// CHECK FOR Google Slides ENTRY
export const getGoogleSyncEntry = (
  data
) => {
  return fetch('https://x1hz-knoc-qcpv.e2.xano.io/api:aIaNUgG7/googleSync/haveEntry', {
    method: 'GET',
    headers: {
      "content-type": "application/json",
      "Authorization": `Bearer ${localStorage.getItem('user')}`
    }
  }) 
    .then((response) => response.json())
    .then((data) => {
      return data
    })
    .catch((error) => {
      console.error("Error:", error);
    });
}



// GET MIRO BOARD
export const getMiroBoard = (
  data
) => {
  return fetch(`https://x1hz-knoc-qcpv.e2.xano.io/api:aIaNUgG7/getMiroBoard?boardId=${data}`, {
    method: 'GET',
    headers: {
      "content-type": "application/json",
      "Authorization": `Bearer ${localStorage.getItem('user')}`
    },
  }) 
    .then((response) => response.json())
    .then((data) => {
      return data
    })
    .catch((error) => {
      console.error("Error:", error);
    });
}


// GET USER'S MIRO FRAMES
export const getUserMiroFrames = (
  data
) => {
  return fetch(`https://x1hz-knoc-qcpv.e2.xano.io/api:aIaNUgG7/getMiroFramesByBoard?boardId=${data}`, {
    method: 'GET',
    headers: {
      "content-type": "application/json",
      "Authorization": `Bearer ${localStorage.getItem('user')}`
    },
  }) 
    .then((response) => response.json())
    .then((data) => {
      return data
    })
    .catch((error) => {
      console.error("Error:", error);
    });
}


// GET USER'S Google SLides
export const getUserGoogleSlides = (
  data
) => {
  return fetch('https://x1hz-knoc-qcpv.e2.xano.io/api:aIaNUgG7/getGoogleSlidesByUser', {
    method: 'GET',
    headers: {
      "content-type": "application/json",
      "Authorization": `Bearer ${localStorage.getItem('user')}`
    },
  }) 
    .then((response) => response.json())
    .then((data) => {
      return data
    })
    .catch((error) => {
      console.error("Error:", error);
    });
}

// GET USER'S Google Slides thumbnail and length
export const getUserGoogleSlidesThumbAndLength = (
  data
) => {
  return fetch(`https://x1hz-knoc-qcpv.e2.xano.io/api:aIaNUgG7/getGoogleSlidePages/${data}`, {
    method: 'GET',
    headers: {
      "content-type": "application/json",
      "Authorization": `Bearer ${localStorage.getItem('user')}`
    },
  }) 
    .then((response) => response.json())
    .then((data) => {
      return data
    })
    .catch((error) => {
      console.error("Error:", error);
    });
}


// REMOVE A USER Google Slides integration
export const deleteUserGoogleEntry = (
  data
) => {
  return fetch('https://x1hz-knoc-qcpv.e2.xano.io/api:aIaNUgG7/googlesync', {
    method: 'DELETE',
    headers: {
      "content-type": "application/json",
      "Authorization": `Bearer ${localStorage.getItem('user')}`
    }
  })
    .then((response) => response.json())
    .then((data) => {
      return data
    })
    .catch((error) => {
      console.error("Error:", error);

    });
}

export const sendStoryboardToMiro = (data
  ) => {
    return fetch('https://x1hz-knoc-qcpv.e2.xano.io/api:aIaNUgG7/CreateMiroStoryboard', {
      method: 'POST',
      headers: {
        "content-type": "application/json",
        "Authorization": `Bearer ${localStorage.getItem('user')}`
      },
      body: JSON.stringify({
          boardId: data.boardId,
          projectKey: data.projectKey,
          frameId: data.frameId
        })
    })
      .then((response) => response.json())
      .then((data) => {
        // console.log(data)
        return data
      })
      .catch((error) => {
        console.error("Error:", error);
  
      });
  }

// export to google slides
export const exportToGSlides = (
  data
) => {
  return fetch(`https://x1hz-knoc-qcpv.e2.xano.io/api:aIaNUgG7/exportToSlide`, {
    method: 'POST',
    headers: {
      "content-type": "application/json",
      "Authorization": `Bearer ${localStorage.getItem('user')}`
    },
    body: JSON.stringify({
      presentationID: data.presentationID,
      index: data.index,
      projectKey: data.projectKey
    })
  })
    .then((response) => response.json())
    .catch((error) => {
      console.error("Error:", error);
    });
} 

// GET INITIAL GOOGLE AUTH URL
export const getGoogleAuthURL = (
  data
) => {
  return fetch(`https://x1hz-knoc-qcpv.e2.xano.io/api:aIaNUgG7/getGoogleAuthURL?projectKey=${data}`, {
    method: 'GET',
    headers: {
      "content-type": "application/json",
      "Authorization": `Bearer ${localStorage.getItem('user')}`
    }
  })
    .then((response) => response.json())
    .then((data) => {
      return data
    })
    .catch((error) => {
      console.error("Error:", error);
    });
}


// GET INITIAL MIRO AUTH URL
export const getMiroAuthURL = (
  data
) => {
  return fetch(`https://x1hz-knoc-qcpv.e2.xano.io/api:aIaNUgG7/getMiroAuthURL?projectKey=${data}`, {
    method: 'GET',
    headers: {
      "content-type": "application/json",
      "Authorization": `Bearer ${localStorage.getItem('user')}`
    }
  })
    .then((response) => response.json())
    .then((data) => {
      return data
    })
    .catch((error) => {
      console.error("Error:", error);
    });
}


// GET GOOGLE PICKER CREDENTIALS
export const getGooglePickerCreds = (
  data
) => {
  return fetch(`https://x1hz-knoc-qcpv.e2.xano.io/api:aIaNUgG7/getGooglePickerCreds`, {
    method: 'GET',
    headers: {
      "content-type": "application/json",
      "Authorization": `Bearer ${localStorage.getItem('user')}`
    }
  })
    .then((response) => response.json())
    .then((data) => {
      return data
    })
    .catch((error) => {
      console.error("Error:", error);
    });
}


// GET USER'S STRIPE SUBSCRIPTION STATUS
export const getStripeSubStatus = (
) => {
  return fetch(`https://x1hz-knoc-qcpv.e2.xano.io/api:UQuTJ3vx/subscription_status`, {
    method: 'GET',
    headers: {
      "content-type": "application/json",
      "Authorization": `Bearer ${localStorage.getItem('user')}`
    },
  }) 
    .then((response) => response.json())
    .then((data) => {
      return data
    })
    .catch((error) => {
      console.error("Error:", error);
    });
}



// GET STRIPE CHECKOUT URL
export const getStripeCheckoutURL = (
  data
) => {
  return fetch(`https://x1hz-knoc-qcpv.e2.xano.io/api:UQuTJ3vx/sessions`, {
    method: 'POST',
    headers: {
      "content-type": "application/json",
      "Authorization": `Bearer ${localStorage.getItem('user')}`
    },
    body: JSON.stringify({
      success_url: data.success_url,
      cancel_url: data.cancel_url,
      subscription: data.subscription
    })
  })
    .then((response) => response.json())
    .catch((error) => {
      console.error("Error:", error);
    });
}


// GET ALL POSES FOR A SPECIFIC MODEL
export const getPoses = ( 
  data
) => {
  return fetch(`https://x1hz-knoc-qcpv.e2.xano.io/api:Xu_TAyFO/preset_model_file?object=${data}`, {
    method: 'GET',
    headers: {
      "content-type": "application/json",
      "Authorization": `Bearer ${localStorage.getItem('user')}`
    },
  }) 
    .then((response) => response.json())
    .then((data) => {
      return data
    })
    .catch((error) => {
      console.error("Error:", error);
    });
}
// GET SINGLE POSE
export const getSinglePose = ( 
  data
) => {
  return fetch(`https://x1hz-knoc-qcpv.e2.xano.io/api:Xu_TAyFO/preset_model_file/${data}`, {
    method: 'GET',
    headers: {
      "content-type": "application/json",
      "Authorization": `Bearer ${localStorage.getItem('user')}`
    }, 
  }) 
    .then((response) => response.json())
    .then((data) => {
      return data
    })
    .catch((error) => {
      console.error("Error:", error);
    });
}
// GET MODEL URLS
export const getModelURLs = ( 
  data
) => {
  return fetch(`https://x1hz-knoc-qcpv.e2.xano.io/api:aIaNUgG7/project/${data}/model_urls`, {
    method: 'GET',
    headers: {
      "content-type": "application/json",
      "Authorization": `Bearer ${localStorage.getItem('user')}`
    },
  }) 
    .then((response) => response.json())
    .then((data) => {
      return data
    })
    .catch((error) => {
      console.error("Error:", error);
    });
}

// GET TEPLATE SCENES BY CATEGORY
export const getTemplateScenes = ( 
  data
) => {
  return fetch(`https://x1hz-knoc-qcpv.e2.xano.io/api:Xu_TAyFO/template_scenes?category=${data}`, {
    method: 'GET',
    headers: {
      "content-type": "application/json",
      "Authorization": `Bearer ${localStorage.getItem('user')}`
    },
  }) 
    .then((response) => response.json())
    .then((data) => {
      return data
    })
    .catch((error) => {
      console.error("Error:", error);
    });
}

// TEST FIGMA FRAME WORKS
export const testFigmaFrame = ( 
  data
) => {
  return fetch(data, {
    method: 'GET',
    headers: {
      "content-type": "application/json"
    },
  }) 
    .then((response) => response.json())
    .then((data) => {
      return data
    })
    .catch((error) => {
      console.error("Error:", error);
    });
}