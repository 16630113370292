import React, { useRef, useEffect, Suspense, useState, useMemo, useCallback } from "react";
import { TransformControls, PivotControls, Edges, useGLTF, Box } from "@react-three/drei";

import { useXR, useXREvent } from "@react-three/xr";
import { useFrame } from "react-three-fiber";
import { Tween } from "three";

import * as THREE from "three";
 
import debounce from 'lodash.debounce';


import { useStore } from "../zustand/objects";
import VRControls from "./VRControls";

import ModelObject from "./ModelObject"

import {Button, ButtonTexture} from "./ModelFiles/UI/Button"
import {CurvedPanels, CurvedPanelsTexture} from "./ModelFiles/UI/CurvedPanels"
import {FlatPanels, FlatPanelsTexture} from "./ModelFiles/UI/FlatPanels"
import Slider from "./ModelFiles/UI/Slider"
import {StackedSidebar, StackedSidebarTexture} from "./ModelFiles/UI/StackedSidebar"
import Toggle from "./ModelFiles/UI/Toggle"
import UpwardsFacingPanel from "./ModelFiles/UI/UpwardsFacingPanel"
import {RoundedWindow, RoundedWindowTexture} from "./ModelFiles/UI/RoundedWindow"
import {RoundedTabBar,RoundedTabBarTexture} from "./ModelFiles/UI/RoundedTabBar"
import {RoundedToolbar, RoundedToolbarTexture} from "./ModelFiles/UI/RoundedToolbar"
import {TabBar,TabBarTexture} from "./ModelFiles/UI/TabBar"
import {Toolbar, ToolbarTexture} from "./ModelFiles/UI/Toolbar"
import {Window, WindowTexture} from "./ModelFiles/UI/Window"
import {AppleUI, BackgroundUI, MicrosoftUI, FloatgridsLayout, FloatgridsUI} from "./ModelFiles/UI/AppleUI"

import { ConeShape, ConeShapeTexture } from "./ModelFiles/Shape/ConeShape";
import { CubeShape, CubeShapeTexture } from "./ModelFiles/Shape/CubeShape";
import { CylinderShape, CylinderShapeTexture } from "./ModelFiles/Shape/CylinderShape";
import { RoundedBoxShape, RoundedBoxShapeTexture } from "./ModelFiles/Shape/RoundedBoxShape";
import { SphereShape, SphereShapeTexture } from "./ModelFiles/Shape/SphereShape";
import { ConstructionOutlined } from "@mui/icons-material";

import { getSpecificUploadModel, getSpecificAIModel } from "../api";

export const Model = ({ destination, orbitControls, position, rotation, scale, id, currentObjectArtboard, objectsAreLoaded, imageTexture, object, colour, category, pose, isLocked, matrixState, curved, curveAmount, modelPath }) => {


  const components = { 
    apple: AppleUI,
    background: BackgroundUI,
    microsoft: MicrosoftUI,
    floatgridsUi: FloatgridsUI,
    floatgridsLayout: FloatgridsLayout,
    cone: ConeShape,
    cube: CubeShape,
    cylinder: CylinderShape,
    roundedBox: RoundedBoxShape,
    sphere: SphereShape
  };

  // Correct! JSX type can be a capitalized variable.
  const SpecificStory = components[object] ? components[object] : null;

  const {
    updateObjectSelected,
    transformModeChange,
    selectedObjectID,
    transformMode,
    updateObjectMatrix,
    setIsDraggingModel,
    isDraggingModel,
    grouping,
    addToGroupedObjects,
    groupedObjects
  } = useStore();

  const transformControls = useRef();
  const transformChild = useRef();




  const grabcontrols = useRef()

  async function updatePositionVR() {
    updateObjectMatrix({
      id,
      currentObjectArtboard,
      matrix: new THREE.Matrix4().copy(grabcontrols.current.matrixWorld),
      undo: false
    })
  }


const [vrHovered, setVrHovered] = useState(false)
const [vrSelected, setVrSelected] = useState(false)
const [vrDragging, setVrDragging] = useState(false)

const xr = useXR()

const handleVRSelectEnd = () => {
  if (xr.isPresenting) {
       if (vrSelected){
       updatePositionVR().then(() => grabcontrols.current.position.set(
         0,0,0
       )).then(() => grabcontrols.current.rotation.set(
        0,0,0
      )).then(() => setVrSelected(false))
}
    }
}

useXREvent('selectend', () => handleVRSelectEnd())


const handleDrag = useCallback(
  debounce(
    (e) =>
    selectedObjectID === id ? ObjectUpdate(e) : ''
  , 60)
  )

 
  // declare empty position, rotation and scale for .decompose()
  const pos = new THREE.Vector3()
  const rot = new THREE.Quaternion()
  const sca = new THREE.Vector3()

  // get local copy of matrixState
  const newMatrix = new THREE.Matrix4().copy(matrixState)

  // decompose newMatrix and apply to pos, rot and sca
  newMatrix.decompose(pos, rot, sca)
  // create matrix for gizmo that doesn't include scale, which is applied to the group instead
  const [stateGrabbed, setStateGrabbed] = useState(new THREE.Matrix4().copy(matrixState))
  const gizmoMatrix = new THREE.Matrix4().compose(pos, rot, new THREE.Vector3(1, 1, 1))
  // the below stops the object snapping back to old position while moving
  const [gizmoMatrixx, setGizmoMatrixx] = useState(gizmoMatrix)
  


  const [interimMatrix, setInterimMatrix] = useState(matrixState)

  const startDragging = () => {
    setIsDraggingModel(true)
  }

  const stopDragging = () => {
    setIsDraggingModel(false)
  }

  const handleDragging = useCallback(
    debounce(
      (e) =>
      selectedObjectID === id && setInterimMatrix(e)
    ,30))

  const ObjectUpdate = (e) => {
    const pos2 = new THREE.Vector3()
    const rot2 = new THREE.Quaternion()
    const sca2 = new THREE.Vector3()
    const toDecompose = new THREE.Matrix4().copy(e)
    toDecompose.decompose(pos2, rot2, sca2)
    // send update to zustand
    // console.log(id)
    updateObjectMatrix({
      id,
      currentObjectArtboard,
      matrix: e,
      undo: false
    })}


  const missedShape = () => {
    !isLocked && !isDraggingModel && updateObjectSelected('0')
    transformModeChange({
      transformMode: "translate"
    })
  };


 
  const passdownFunctionEditor = (e) => {
    // !isDraggingModel && !isLocked ? !grouping ? updateObjectSelected(id) : addToGroupedObjects(id) : updateObjectSelected('0') && transformModeChange({
    if (isDraggingModel === false) {
    !isLocked ? !grouping ? updateObjectSelected(id) : addToGroupedObjects(id) : updateObjectSelected('0')  }
    }




  if (!objectsAreLoaded) {
    return
  } else { 
    return (
      selectedObjectID === id ? !isLocked ? transformMode === "translate" ?
        <group 
        onPointerMissed={(e) => {
          // e.stopPropagation()
          // console.log("miss")
         missedShape()
        }}
        >
          <PivotControls
            depthTest={false}
            lineWidth={5}
            matrix={!isDraggingModel && new THREE.Matrix4().copy(matrixState)}
            active={selectedObjectID === id ? [true, true, true] : [false, false, false]}
            onDragStart={() => {
              startDragging()
            }}
            onDragEnd={() => {
              ObjectUpdate(interimMatrix)
              stopDragging()}}
            onDrag={(e) => {
              handleDragging(e)}}

            ref={transformControls}
            visible={selectedObjectID === id ? true : false}
            fixed={true}
            scale={150}
            annotations={false}
          >
            
            <group ref={transformChild}>
              <Suspense fallback={null}>
              {(category === "shape" || category === "apple" || category === "background" || category === "microsoft" || category === "floatgridsUi" || category === "floatgridsLayout") ? SpecificStory && <SpecificStory story={object} clickFunction={passdownFunctionEditor} missFunction={missedShape} colour={colour} pose={pose} curved={curved} curveAmount={curveAmount} imageTexture={imageTexture} />
              : <ModelObject modelPath={modelPath} story={object} clickFunction={passdownFunctionEditor} missFunction={missedShape} colour={colour} pose={pose} curved={curved} curveAmount={curveAmount} imageTexture={imageTexture} />}
              </Suspense>
            </group>
          </PivotControls></group>
        :
  
        <TransformControls
          onPointerMissed={(e) => {
            e.stopPropagation()
            missedShape()
          }}
          position={objectsAreLoaded && new THREE.Vector3().setFromMatrixPosition(matrixState)}
          rotation={objectsAreLoaded && new THREE.Euler().setFromQuaternion(rot)}
          scale={objectsAreLoaded && new THREE.Vector3().setFromMatrixScale(matrixState)}
          showY={selectedObjectID === id ? true : false}
          showX={selectedObjectID === id ? true : false}
          showZ={selectedObjectID === id ? true : false}
          translationSnap={0.125}
          scaleSnap={0.025}
          ref={transformControls}
          mode={'scale'}
        >
          <group ref={transformChild}>
            <Suspense fallback={null}>
            {(category === "shape" || category === "apple" || category === "background" || category === "microsoft" || category === "floatgridsUi" || category === "floatgridsLayout") ? SpecificStory && <SpecificStory story={object} clickFunction={passdownFunctionEditor} missFunction={missedShape} colour={colour} pose={pose} curved={curved} curveAmount={curveAmount} imageTexture={imageTexture} />
              : <ModelObject modelPath={modelPath} story={object} clickFunction={passdownFunctionEditor} missFunction={missedShape} colour={colour} pose={pose} curved={curved} curveAmount={curveAmount} imageTexture={imageTexture} />}
            </Suspense>
          </group>
        </TransformControls>

        :

        <TransformControls
          position={objectsAreLoaded && new THREE.Vector3().setFromMatrixPosition(matrixState)}
          rotation={objectsAreLoaded && new THREE.Euler().setFromQuaternion(rot)}
          scale={objectsAreLoaded && new THREE.Vector3().setFromMatrixScale(matrixState)}
          showX={false}
          showY={false}
          showZ={false}
          translationSnap={0.125}
          scaleSnap={(category === "shape") ? 0.025 : 0.5}
          ref={transformControls}
          mode={transformMode}
        >
          <Suspense fallback={null}>
          {(category === "shape" || category === "apple" || category === "background" || category === "microsoft" || category === "floatgridsUi" || category === "floatgridsLayout") ? SpecificStory && <SpecificStory story={object} clickFunction={passdownFunctionEditor} missFunction={missedShape} colour={colour} pose={pose} curved={curved} curveAmount={curveAmount} imageTexture={imageTexture} />
              : <ModelObject modelPath={modelPath} story={object} clickFunction={passdownFunctionEditor} missFunction={missedShape} colour={colour} pose={pose} curved={curved} curveAmount={curveAmount} imageTexture={imageTexture} />}
          </Suspense>
        </TransformControls>
        :
        <VRControls 
        isLocked={isLocked}
        idprop={id}
        currentObjectArtboard={currentObjectArtboard}
        objectsAreLoaded={objectsAreLoaded}
        matrixState={new THREE.Matrix4().copy(matrixState)}
        rot={rot}
        scale={sca}
        >
          <Suspense fallback={null}>
          {(category === "shape" || category === "apple" || category === "background" || category === "microsoft" || category === "floatgridsUi" || category === "floatgridsLayout") ? SpecificStory && <SpecificStory story={object} clickFunction={passdownFunctionEditor} missFunction={missedShape} colour={colour} pose={pose} curved={curved} curveAmount={curveAmount} imageTexture={imageTexture} />
              : <ModelObject modelPath={modelPath} story={object} clickFunction={passdownFunctionEditor} missFunction={missedShape} colour={colour} pose={pose} curved={curved} curveAmount={curveAmount} imageTexture={imageTexture} />}
          </Suspense>
         </VRControls>
    );
  }}


// UIModelTexture TO BE PUT IN OWN OBJECT
export const UIModelTexture = ({ orbitControls, undoing, position, rotation, scale, id, currentObjectArtboard, objectsAreLoaded, object, colour, category, pose, imageTexture, isLocked, matrixState }) => {

  const components = {
      button: ButtonTexture,
      curvedPanels: CurvedPanelsTexture,
      flatPanels: FlatPanelsTexture,
      stackedSidebar: StackedSidebarTexture,
      roundedWindow: RoundedWindow,
          roundedTabBar: RoundedTabBarTexture,
          roundedToolbar: RoundedToolbarTexture,
          tabBar: TabBarTexture,
          toolBar: ToolbarTexture,
          window: WindowTexture
  };



  // Correct! JSX type can be a capitalized variable.
  const SpecificStory = components[object];

  // first load of model url triggers network request.
  // subsequent loads of same model url don't
  // file is stored in disk cache

  const {
    updateObjectSelected,
    selectedObjectID,
    transformMode,
    updateObjectMatrix,
    transformModeChange,
    setIsDraggingModel,
    isDraggingModel
  } = useStore();

  const transformControls = useRef();
  const transformChild = useRef();


  const passdownFunctionEditor = () => {
  //  !isDraggingModel && !isLocked ? !grouping ? updateObjectSelected(id) : addToGroupedObjects(id) : updateObjectSelected('0')
  if (isDraggingModel === false) {
    !isLocked ? updateObjectSelected(id) : updateObjectSelected('0')  }
    // : (groupedObjects.find(id) === true) ? removeFromGroupedObjects(id) : addToGroupedObjects(id) 

  };

  // declare empty position, rotation and scale for .decompose()
  const pos = new THREE.Vector3()
  const rot = new THREE.Quaternion()
  const sca = new THREE.Vector3()

  // get local copy of matrixState
  const newMatrix = new THREE.Matrix4().copy(matrixState)

  // decompose newMatrix and apply to pos, rot and sca
  // newMatrix.decompose(pos, rot, sca)
  newMatrix.copy(matrixState).decompose(pos, rot, sca)
  // create matrix for gizmo that doesn't include scale, which is applied to the group instead
  const gizmoMatrix = new THREE.Matrix4().compose(pos, rot, new THREE.Vector3(1, 1, 1))
  // the below stops the object snapping back to old position while moving
  const [gizmoMatrixx, setGizmoMatrixx] = useState(gizmoMatrix)
  const [interimMatrix, setInterimMatrix] = useState(matrixState)

  const startDragging = () => {
    setIsDraggingModel(true)
  }

  const stopDragging = () => {
    setIsDraggingModel(false)
  }

  const handleDragging = useCallback(
    debounce(
      (e) =>
      selectedObjectID === id && setInterimMatrix(e)
    ,30))

  const ObjectUpdate = (e) => {
    const pos2 = new THREE.Vector3()
    const rot2 = new THREE.Quaternion()
    const sca2 = new THREE.Vector3()
    const toDecompose = new THREE.Matrix4().copy(e)
    toDecompose.decompose(pos2, rot2, sca2)
    // send update to zustand
    updateObjectMatrix({
      id,
      currentObjectArtboard,
      matrix: e,
      undo: false
    })
  }

  // this scale update isn't perfect, needs work

  const [transformScale, setTransformScale] = useState(sca)

 

  const missedShape = () => {
    !isDraggingModel && updateObjectSelected('0')
    transformModeChange({
      transformMode: "translate"
    })
  };
   

  const grabcontrols = useRef()

  async function updatePositionVR() {
    updateObjectMatrix({
      id,
      currentObjectArtboard,
      matrix: new THREE.Matrix4().copy(grabcontrols.current.matrixWorld),
      undo: false
    })
  }


const [vrHovered, setVrHovered] = useState(false)
const [vrSelected, setVrSelected] = useState(false)
const [vrDragging, setVrDragging] = useState(false)

const xr = useXR()
 
const handleVRSelectEnd = () => {
  if (xr.isPresenting) {
       if (vrSelected){
       updatePositionVR().then(() => grabcontrols.current.position.set(
         0,0,0
       )).then(() => grabcontrols.current.rotation.set(
        0,0,0
      )).then(() => setVrSelected(false))
}
    }
}

useXREvent('selectend', () => handleVRSelectEnd())


const handleDrag = useCallback(
  debounce(
    (e) =>
    selectedObjectID === id ? ObjectUpdate(e) : ''
  , 60)
  )

  if (!objectsAreLoaded) {
    return <div></div>;
  } else {
    return (
      selectedObjectID === id ? !isLocked ? transformMode === "translate" ?
        <group onPointerMissed={(e) => {
          // e.stopPropagation()
          missedShape()
        }}>
          <PivotControls
            // onDragStart={(e) => console.log(transformControls.current)}
            depthTest={false}
            matrix={!isDraggingModel && new THREE.Matrix4().copy(matrixState)}
            active={selectedObjectID === id ? [true, true, true] : [false, false, false]}

            onDragStart={() => startDragging()}
            onDragEnd={() => {
              ObjectUpdate(interimMatrix)
              stopDragging()}}
            onDrag={(e) => handleDragging(e)}

            
            // onDrag={(e) => {handleDrag(e)}}
            // onDragEnd={() => selectedObjectID === id ? ObjectUpdate() : ''}
            ref={transformControls}
            visible={selectedObjectID === id ? true : false}
            fixed={true}
             scale={150}
            annotations={false}
          >
            <group ref={transformChild}>
              <Suspense fallback={null}>
              {SpecificStory && <SpecificStory story={object} clickFunction={passdownFunctionEditor} colour={colour} pose={pose} imageTexture={imageTexture} />}
              </Suspense>
            </group>
          </PivotControls>
        </group>
        :

        <TransformControls
          onPointerMissed={(e) => {
            e.stopPropagation()
            missedShape()
          }}
          position={objectsAreLoaded && new THREE.Vector3().setFromMatrixPosition(matrixState)}
          rotation={objectsAreLoaded && new THREE.Euler().setFromQuaternion(rot)}
          scale={objectsAreLoaded && new THREE.Vector3().setFromMatrixScale(matrixState)}
          showY={selectedObjectID === id ? true : false}
          showX={selectedObjectID === id ? true : false}
          showZ={selectedObjectID === id ? true : false}
          translationSnap={0.125}
          scaleSnap={0.025}
          ref={transformControls}
          mode={'scale'}

        >
          <group ref={transformChild}>
            <Suspense fallback={null}>
            {SpecificStory && <SpecificStory story={object} clickFunction={passdownFunctionEditor} colour={colour} pose={pose} imageTexture={imageTexture} />}
            </Suspense>
          </group>
        </TransformControls>
        :

        <TransformControls

          // onPointerMissed={() => {
          //   missedShape()
          // }}
          position={objectsAreLoaded && new THREE.Vector3().setFromMatrixPosition(matrixState)}
          rotation={objectsAreLoaded && new THREE.Euler().setFromQuaternion(rot)}
          scale={objectsAreLoaded && new THREE.Vector3().setFromMatrixScale(matrixState)}
          showX={false}
          showY={false}
          showZ={false}
          translationSnap={0.125}
          // rotationSnap={0.31416}
          scaleSnap={(category === "shape") ? 0.025 : 0.5}
          ref={transformControls}
          mode={transformMode}
        >
          <Suspense fallback={null}>
          {SpecificStory && <SpecificStory story={object} clickFunction={passdownFunctionEditor} colour={colour} pose={pose} imageTexture={imageTexture} />}
          </Suspense>
        </TransformControls>
        :
      <VRControls 
        isLocked={isLocked}
        idprop={id}
        currentObjectArtboard={currentObjectArtboard}
        objectsAreLoaded={objectsAreLoaded}
        matrixState={new THREE.Matrix4().copy(matrixState)}
        rot={rot}
        scale={sca}
        >

        <Suspense fallback={null}>
          <SpecificStory story={object} clickFunction={passdownFunctionEditor} colour={colour} pose={pose} scale={scale} imageTexture={imageTexture}  />
        </Suspense>

        </VRControls>
    )
  }
};




// SHAPETEXTURE TO BE PUT IN OWN OBJECT
export const ShapeTexture = ({ orbitControls, undoing, position, rotation, scale, id, currentObjectArtboard, objectsAreLoaded, object, colour, category, pose, imageTexture, isLocked, matrixState }) => {

  const components = {
    cone: ConeShapeTexture,
    cube: CubeShapeTexture,
    cylinder: CylinderShapeTexture,
    roundedBox: RoundedBoxShapeTexture,
    sphere: SphereShapeTexture
  };



  // Correct! JSX type can be a capitalized variable.
  const SpecificStory = components[object];

  // first load of model url triggers network request.
  // subsequent loads of same model url don't
  // file is stored in disk cache

  const {
    updateObjectSelected,
    selectedObjectID,
    transformMode,
    updateObjectMatrix,
    transformModeChange,
    setOverTransformControls,
    grouping,
    groupedObjects,
    removeFromGroupedObjects,
    addToGroupedObjects,
    setIsDraggingModel,
    isDraggingModel
  } = useStore();

  const transformControls = useRef();
  const transformChild = useRef();


  const passdownFunctionEditor = () => {
    if (isDraggingModel === false) {
      !isLocked ? updateObjectSelected(id) : updateObjectSelected('0')  }
    // !isDraggingModel && !isLocked ? !grouping ? updateObjectSelected(id) : addToGroupedObjects(id) : updateObjectSelected('0')

  };

  // declare empty position, rotation and scale for .decompose()
  const pos = new THREE.Vector3()
  const rot = new THREE.Quaternion()
  const sca = new THREE.Vector3()

  // get local copy of matrixState
  const newMatrix = new THREE.Matrix4().copy(matrixState)

  // decompose newMatrix and apply to pos, rot and sca
  // newMatrix.decompose(pos, rot, sca)
  newMatrix.copy(matrixState).decompose(pos, rot, sca)
  // create matrix for gizmo that doesn't include scale, which is applied to the group instead
  const gizmoMatrix = new THREE.Matrix4().compose(pos, rot, new THREE.Vector3(1, 1, 1))
  // the below stops the object snapping back to old position while moving
  const [gizmoMatrixx, setGizmoMatrixx] = useState(gizmoMatrix)
  // const xrControllerRef = React.createRef();

  const [interimMatrix, setInterimMatrix] = useState(matrixState)

  const startDragging = () => {
    setIsDraggingModel(true)
  }

  const stopDragging = () => {
    setIsDraggingModel(false)
  }

  const handleDragging = useCallback(
    debounce(
      (e) =>
      selectedObjectID === id && setInterimMatrix(e)
    ,30))

  const ObjectUpdate = (e) => {
    const pos2 = new THREE.Vector3()
    const rot2 = new THREE.Quaternion()
    const sca2 = new THREE.Vector3()
    const toDecompose = new THREE.Matrix4().copy(e)
    toDecompose.decompose(pos2, rot2, sca2)
    // send update to zustand
    updateObjectMatrix({
      id,
      currentObjectArtboard,
      matrix: e,
      undo: false
      // matrix: new THREE.Matrix4().compose(pos2, rot2, sca2),
      // matrix: new THREE.Matrix4().copy(e),
    })
    // setStateGrabbed(new THREE.Matrix4().copy(e))
    // before changed scale to make sure gizmo is right but not skewed in scale,
    // but now we're carrying the gizmo's matrix anyway, can just use e as is
    // setGizmoMatrixx(e)
    // setGizmoMatrixx(e)
    // setGizmoMatrixx(new THREE.Matrix4().compose(pos2, rot2, new THREE.Vector3(1, 1, 1)))

    
    // const toDecompose = new THREE.Matrix4().copy(transformChild.current.matrixWorld)
    
    // setGizmoMatrixx(new THREE.Matrix4().compose(pos2, rot2, new THREE.Vector3(1, 1, 1)))
  }

  // this scale update isn't perfect, needs work


  const missedShape = () => {
    !isDraggingModel &&  updateObjectSelected('0')
    transformModeChange({
      transformMode: "translate"
    })
  };
  

  const grabcontrols = useRef()

  async function updatePositionVR() {
    updateObjectMatrix({
      id,
      currentObjectArtboard,
      matrix: new THREE.Matrix4().copy(grabcontrols.current.matrixWorld),
      undo: false
    })
  }


const [vrHovered, setVrHovered] = useState(false)
const [vrSelected, setVrSelected] = useState(false)
const [vrDragging, setVrDragging] = useState(false)

const xr = useXR()

const handleVRSelectEnd = () => {
  if (xr.isPresenting) {
       if (vrSelected){
       updatePositionVR().then(() => grabcontrols.current.position.set(
         0,0,0
       )).then(() => grabcontrols.current.rotation.set(
        0,0,0
      )).then(() => setVrSelected(false))
}
    }
}

useXREvent('selectend', () => handleVRSelectEnd())


const handleDrag = useCallback(
  debounce(
    (e) =>
    selectedObjectID === id ? ObjectUpdate(e) : ''
  , 60)
  )

  if (!objectsAreLoaded) {
    return <div></div>;
  } else {
    return (
      selectedObjectID === id ? !isLocked ? transformMode === "translate" ?
        <group onPointerMissed={(e) => {
          e.stopPropagation()
          missedShape()
        }}>
          <PivotControls
            depthTest={false}
            matrix={!isDraggingModel && new THREE.Matrix4().copy(matrixState)}
            active={selectedObjectID === id ? [true, true, true] : [false, false, false]}
            onDragStart={() => startDragging()}
            onDragEnd={() => {
              ObjectUpdate(interimMatrix)
              stopDragging()}}
            onDrag={(e) => handleDragging(e)}

            ref={transformControls}
            visible={selectedObjectID === id ? true : false}
            fixed={true}
             scale={150}
            annotations={false}
          >
            <group ref={transformChild}>
              <Suspense fallback={null}>
              {SpecificStory && <SpecificStory story={object} clickFunction={passdownFunctionEditor} colour={colour} pose={pose} imageTexture={imageTexture} />}
              </Suspense>
            </group>
          </PivotControls>
        </group>
        :

        <TransformControls
          onPointerMissed={(e) => {
            e.stopPropagation()
            missedShape()
          }}
          position={objectsAreLoaded && new THREE.Vector3().setFromMatrixPosition(matrixState)}
          rotation={objectsAreLoaded && new THREE.Euler().setFromQuaternion(rot)}
          scale={objectsAreLoaded && new THREE.Vector3().setFromMatrixScale(matrixState)}
          showY={selectedObjectID === id ? true : false}
          showX={selectedObjectID === id ? true : false}
          showZ={selectedObjectID === id ? true : false}
          translationSnap={0.125}
          scaleSnap={0.025}
          ref={transformControls}
          mode={'scale'}

        >
          <group ref={transformChild}>
            <Suspense fallback={null}>
            {SpecificStory && <SpecificStory story={object} clickFunction={passdownFunctionEditor} colour={colour} pose={pose} imageTexture={imageTexture} />}
            </Suspense>
          </group>
        </TransformControls>
        :

        <TransformControls

          // onPointerMissed={() => {
          //   missedShape()
          // }}
          position={objectsAreLoaded && new THREE.Vector3().setFromMatrixPosition(matrixState)}
          rotation={objectsAreLoaded && new THREE.Euler().setFromQuaternion(rot)}
          scale={objectsAreLoaded && new THREE.Vector3().setFromMatrixScale(matrixState)}
          showX={false}
          showY={false}
          showZ={false}
          translationSnap={0.125}
          // rotationSnap={0.31416}
          scaleSnap={(category === "shape") ? 0.025 : 0.5}
          ref={transformControls}
          mode={transformMode}
        >
          <Suspense fallback={null}>
            <SpecificStory story={object} clickFunction={passdownFunctionEditor} colour={colour} pose={pose} imageTexture={imageTexture} />
          </Suspense>
        </TransformControls>
        :
      <VRControls 
        isLocked={isLocked}
        idprop={id}
        currentObjectArtboard={currentObjectArtboard}
        objectsAreLoaded={objectsAreLoaded}
        matrixState={new THREE.Matrix4().copy(matrixState)}
        rot={rot}
        scale={sca}
        >

        <Suspense fallback={null}>
          <SpecificStory story={object} clickFunction={passdownFunctionEditor} colour={colour} pose={pose} scale={scale} imageTexture={imageTexture}  />
        </Suspense>

        </VRControls>
    )
  }
};






export function UploadedModel({ uploadedURL, uploadId, destination, orbitControls, position, rotation, scale, id, currentObjectArtboard, objectsAreLoaded, object, colour, category, pose, isLocked, matrixState }) {

  const {
    updateObjectSelected,
    selectedObjectID,
    transformMode,
    updateObjectMatrix,
    transformModeChange,
    grouping,
    addToGroupedObjects,
    setIsDraggingModel,
    isDraggingModel
  } = useStore();

  const [hide, setHide] = useState(true)

  //if model isn't found then we throw a hide state and dont try and load it
  const checkResult = (val) => {
    val === 404 ? setHide(true) :
    val.model.url === uploadedURL ? 
    val.id === uploadId ? setHide(false) : setHide(true) 
    : setHide(true)
  }

  //check if we can/can't match model url and id in database 
  useEffect(() => {
    getSpecificUploadModel(uploadId).then(res => checkResult(res))
  },[])

  const transformControls = useRef();
  const transformChild = useRef();

  const passdownFunctionEditor = () => {
    // !isDraggingModel && !isLocked ? !grouping ? updateObjectSelected(id) : addToGroupedObjects(id) : updateObjectSelected('0') 
    if (isDraggingModel === false) {
      !isLocked ? updateObjectSelected(id) : updateObjectSelected('0')  }

  };

  // declare empty position, rotation and scale for .decompose()
  const pos = new THREE.Vector3()
  const rot = new THREE.Quaternion()
  const sca = new THREE.Vector3()

  // get local copy of matrixState
  const newMatrix = new THREE.Matrix4().copy(matrixState)

  // decompose newMatrix and apply to pos, rot and sca
  newMatrix.copy(matrixState).decompose(pos, rot, sca)
  // create matrix for gizmo that doesn't include scale, which is applied to the group instead
  const gizmoMatrix = new THREE.Matrix4().compose(pos, rot, new THREE.Vector3(1, 1, 1))
  // the below stops the object snapping back to old position while moving
  const [gizmoMatrixx, setGizmoMatrixx] = useState(gizmoMatrix)

  const [interimMatrix, setInterimMatrix] = useState(matrixState)

  const startDragging = () => {
    setIsDraggingModel(true)
  }

  const stopDragging = () => {
    setIsDraggingModel(false)
  }

  const handleDragging = useCallback(
    debounce(
      (e) =>
      selectedObjectID === id && setInterimMatrix(e)
    ,30))

  const ObjectUpdate = (e) => {
    const pos2 = new THREE.Vector3()
    const rot2 = new THREE.Quaternion()
    const sca2 = new THREE.Vector3()
    const toDecompose = new THREE.Matrix4().copy(e)
    toDecompose.decompose(pos2, rot2, sca2)
    // send update to zustand
    updateObjectMatrix({
      id,
      currentObjectArtboard,
      matrix: e,
      undo: false
    })
  }


  const missedShape = () => {
    !isDraggingModel &&  updateObjectSelected('0')
    transformModeChange({
      transformMode: "translate"
    })
  };
  

  const grabcontrols = useRef()

  async function updatePositionVR() {
    updateObjectMatrix({
      id,
      currentObjectArtboard,
      matrix: new THREE.Matrix4().copy(grabcontrols.current.matrixWorld),
      undo: false
    })
  }


const [vrHovered, setVrHovered] = useState(false)
const [vrSelected, setVrSelected] = useState(false)
const [vrDragging, setVrDragging] = useState(false)

const xr = useXR()

const handleVRSelectEnd = () => {
  if (xr.isPresenting) {
       if (vrSelected){
       updatePositionVR().then(() => grabcontrols.current.position.set(
         0,0,0
       )).then(() => grabcontrols.current.rotation.set(
        0,0,0
      )).then(() => setVrSelected(false))
}
    }
}

useXREvent('selectend', () => handleVRSelectEnd())


const handleDrag = useCallback(
  debounce(
    (e) =>
    selectedObjectID === id ? ObjectUpdate(e) : ''
  , 60)
  )

  if (!objectsAreLoaded) {
    return <div></div>;
  } else {
    return (

      selectedObjectID === id ? !isLocked ? transformMode === "translate" ?
        <group onPointerMissed={(e) => {
          e.stopPropagation()
          missedShape()
        }}>
          <PivotControls

            depthTest={false}
            matrix={!isDraggingModel && new THREE.Matrix4().copy(matrixState)}
            active={selectedObjectID === id ? [true, true, true] : [false, false, false]}
            onDragStart={() => startDragging()}
            onDragEnd={() => {
              ObjectUpdate(interimMatrix)
              stopDragging()}}
            onDrag={(e) => handleDragging(e)}

            ref={transformControls}
            visible={selectedObjectID === id ? true : false}
            fixed={true}
             scale={150}
            annotations={false}
            onClick={() => passdownFunctionEditor()}
          >
            <group ref={transformChild}>
              <Suspense fallback={null}>
              {!hide && <UploadedModelObject uploadedURL={uploadedURL} />}
              {/* <AIModel clickFunction={passdownFunctionEditor} colour={colour} pose={pose} scale={scale} prompt={object}/> */}
              </Suspense>
            </group>
          </PivotControls>
        </group>
        :

        <TransformControls
          onPointerMissed={(e) => {
            e.stopPropagation()
            missedShape()
          }}
          position={objectsAreLoaded && new THREE.Vector3().setFromMatrixPosition(matrixState)}
          rotation={objectsAreLoaded && new THREE.Euler().setFromQuaternion(rot)}
          scale={objectsAreLoaded && new THREE.Vector3().setFromMatrixScale(matrixState)}
          showY={selectedObjectID === id ? true : false}
          showX={selectedObjectID === id ? true : false}
          showZ={selectedObjectID === id ? true : false}
          translationSnap={0.125}
          scaleSnap={0.025}
          ref={transformControls}
          mode={'scale'}
          onClick={() => passdownFunctionEditor()}
        >
          <group ref={transformChild}>
            <Suspense fallback={null}>
            {!hide && <UploadedModelObject uploadedURL={uploadedURL} />}
              {/* <AIModel clickFunction={passdownFunctionEditor} colour={colour} pose={pose} scale={scale} prompt={object}/> */}
            </Suspense>
          </group>
        </TransformControls>
        :

        <TransformControls

          // onPointerMissed={() => {
          //   missedShape()
          // }}
          position={objectsAreLoaded && new THREE.Vector3().setFromMatrixPosition(matrixState)}
          rotation={objectsAreLoaded && new THREE.Euler().setFromQuaternion(rot)}
          scale={objectsAreLoaded && new THREE.Vector3().setFromMatrixScale(matrixState)}
          showX={false}
          showY={false}
          showZ={false}
          translationSnap={0.125}
          // rotationSnap={0.31416}
          scaleSnap={(category === "shape") ? 0.025 : 0.5}
          ref={transformControls}
          mode={transformMode}
          onClick={() => passdownFunctionEditor()}
        >
          <Suspense fallback={null}>
          {!hide && <UploadedModelObject uploadedURL={uploadedURL} />}
              {/* <AIModel clickFunction={passdownFunctionEditor} colour={colour} pose={pose} scale={scale} prompt={object}/> */}
          </Suspense>
        </TransformControls>
        : 
      <VRControls 
        isLocked={isLocked}
        idprop={id}
        currentObjectArtboard={currentObjectArtboard}
        objectsAreLoaded={objectsAreLoaded}
        matrixState={new THREE.Matrix4().copy(matrixState)}
        // matrixState={new THREE.Matrix4().copy(matrixState)}
        rot={rot}
        scale={sca}
        onClick={() => passdownFunctionEditor()}
        >
        <Suspense fallback={null}>
        {!hide && <UploadedModelObject uploadedURL={uploadedURL} />}
              {/* <AIModel clickFunction={passdownFunctionEditor} colour={colour} pose={pose} scale={scale} prompt={object}/> */}
        </Suspense>
        </VRControls>
    );
  }}

 
  export const UploadedModelObject = ({uploadedURL}) => {

    const gltf = useGLTF(uploadedURL)
    const copiedScene = useMemo(() => gltf.scene.clone(), [gltf.scene])
    const { nodes } = useGLTF(uploadedURL)
 
    const primitiveRef = useRef()
// console.log(nodes)

    return (<group scale={1} ref={primitiveRef} >
       <mesh>
        <primitive ref={primitiveRef} position={[0,0,0]} rotation={[0,0,0]} object={copiedScene}/>
        </mesh>       
      </group>
    )
  } 





export function AIModel({ pose, databaseURL, databaseId, id, currentObjectArtboard, objectsAreLoaded, category, isLocked, matrixState, colour }) {

  const {
    updateObjectSelected,
    selectedObjectID,
    transformMode,
    updateObjectMatrix,
    transformModeChange,
    grouping,
    addToGroupedObjects,
    setIsDraggingModel,
    isDraggingModel
  } = useStore();

  const [hide, setHide] = useState(true)

  //if model isn't found then we throw a hide state and dont try and load it
  const checkResult = (val) => {
    val === 404 ? setHide(true) :
    val.model.url === databaseURL ? 
    val.id === databaseId ? setHide(false) : setHide(true) 
    : setHide(true)
  }

  //check if we can/can't match model url and id in database 
  useEffect(() => {
    // console.log(databaseURL, databaseId)
    getSpecificAIModel(databaseId).then(res => checkResult(res))
  },[])

  const transformControls = useRef();
  const transformChild = useRef();

  const passdownFunctionEditor = () => {
    // !isDraggingModel && !isLocked ? !grouping ? updateObjectSelected(id) : addToGroupedObjects(id) : updateObjectSelected('0') 
    if (isDraggingModel === false) {
      !isLocked ? updateObjectSelected(id) : updateObjectSelected('0')  }
  };

  // declare empty position, rotation and scale for .decompose()
  const pos = new THREE.Vector3()
  const rot = new THREE.Quaternion()
  const sca = new THREE.Vector3()

  // get local copy of matrixState
  const newMatrix = new THREE.Matrix4().copy(matrixState)

  // decompose newMatrix and apply to pos, rot and sca
  newMatrix.copy(matrixState).decompose(pos, rot, sca)
  // create matrix for gizmo that doesn't include scale, which is applied to the group instead
  const gizmoMatrix = new THREE.Matrix4().compose(pos, rot, new THREE.Vector3(1, 1, 1))
  // the below stops the object snapping back to old position while moving
  const [gizmoMatrixx, setGizmoMatrixx] = useState(gizmoMatrix)

  const [interimMatrix, setInterimMatrix] = useState(matrixState)

  const startDragging = () => {
    setIsDraggingModel(true)
  }

  const stopDragging = () => {
    setIsDraggingModel(false)
  }

  const handleDragging = useCallback(
    debounce(
      (e) =>
      selectedObjectID === id && setInterimMatrix(e)
    ,30))

  const ObjectUpdate = (e) => {
    const pos2 = new THREE.Vector3()
    const rot2 = new THREE.Quaternion()
    const sca2 = new THREE.Vector3()
    const toDecompose = new THREE.Matrix4().copy(e)
    toDecompose.decompose(pos2, rot2, sca2)
    // send update to zustand
    updateObjectMatrix({
      id,
      currentObjectArtboard,
      matrix: e,
      undo: false
    })
  }


  const missedShape = () => {
    !isDraggingModel &&  updateObjectSelected('0')
    transformModeChange({
      transformMode: "translate"
    })
  };
  

  const grabcontrols = useRef()

  async function updatePositionVR() {
    updateObjectMatrix({
      id,
      currentObjectArtboard,
      matrix: new THREE.Matrix4().copy(grabcontrols.current.matrixWorld),
      undo: false
    })
  }


const [vrHovered, setVrHovered] = useState(false)
const [vrSelected, setVrSelected] = useState(false)
const [vrDragging, setVrDragging] = useState(false)

const xr = useXR()

const handleVRSelectEnd = () => {
  if (xr.isPresenting) {
       if (vrSelected){
       updatePositionVR().then(() => grabcontrols.current.position.set(
         0,0,0
       )).then(() => grabcontrols.current.rotation.set(
        0,0,0
      )).then(() => setVrSelected(false))
}
    } 
}

useXREvent('selectend', () => handleVRSelectEnd())



  if (!objectsAreLoaded) {
    return <div></div>;
  } else {
    return (

      selectedObjectID === id ? !isLocked ? transformMode === "translate" ?
        <group onPointerMissed={(e) => {
          e.stopPropagation()
          missedShape()
        }}>
          <PivotControls
            depthTest={false}
            matrix={!isDraggingModel && new THREE.Matrix4().copy(matrixState)}
            active={selectedObjectID === id ? [true, true, true] : [false, false, false]}

            onDragStart={() => startDragging()}
            onDragEnd={() => {
              ObjectUpdate(interimMatrix)
              stopDragging()}}
            onDrag={(e) => handleDragging(e)}

            ref={transformControls}
            visible={selectedObjectID === id ? true : false}
            fixed={true}
             scale={150}
            annotations={false}
            onClick={() => passdownFunctionEditor()}
          >
            <group ref={transformChild}>
              <Suspense fallback={null}>
              {!hide && <AIModelObject colour={colour} pose={pose} databaseURL={databaseURL} />}
              </Suspense>
            </group>
          </PivotControls>
        </group>
        :

        <TransformControls
          onPointerMissed={(e) => {
            e.stopPropagation()
            missedShape()
          }}
          position={objectsAreLoaded && new THREE.Vector3().setFromMatrixPosition(matrixState)}
          rotation={objectsAreLoaded && new THREE.Euler().setFromQuaternion(rot)}
          scale={objectsAreLoaded && new THREE.Vector3().setFromMatrixScale(matrixState)}
          showY={selectedObjectID === id ? true : false}
          showX={selectedObjectID === id ? true : false}
          showZ={selectedObjectID === id ? true : false}
          translationSnap={0.125}
          scaleSnap={0.025}
          ref={transformControls}
          mode={'scale'}
          onClick={() => passdownFunctionEditor()}
        >
          <group ref={transformChild}>
            <Suspense fallback={null}>
            {!hide && <AIModelObject pose={pose} databaseURL={databaseURL} />}
            </Suspense>
          </group>
        </TransformControls>
        :

        <TransformControls
          position={objectsAreLoaded && new THREE.Vector3().setFromMatrixPosition(matrixState)}
          rotation={objectsAreLoaded && new THREE.Euler().setFromQuaternion(rot)}
          scale={objectsAreLoaded && new THREE.Vector3().setFromMatrixScale(matrixState)}
          showX={false}
          showY={false}
          showZ={false}
          translationSnap={0.125}
          scaleSnap={(category === "shape") ? 0.025 : 0.5}
          ref={transformControls}
          mode={transformMode}
          onClick={() => passdownFunctionEditor()}
        >
          <Suspense fallback={null}>
          {!hide && <AIModelObject pose={pose} databaseURL={databaseURL} />}
          </Suspense>
        </TransformControls>
        :
      <VRControls 
        isLocked={isLocked}
        idprop={id}
        currentObjectArtboard={currentObjectArtboard}
        objectsAreLoaded={objectsAreLoaded}
        matrixState={new THREE.Matrix4().copy(matrixState)}
        // matrixState={new THREE.Matrix4().copy(matrixState)}
        // rot={rot}
        // scale={sca}
        onClick={() => passdownFunctionEditor()}
        >
        <Suspense fallback={null}>
        {!hide && <AIModelObject pose={pose} databaseURL={databaseURL} />}
        </Suspense>
         </VRControls>
    );
  }}
 
  
  

  export const AIModelObject = (props) => {

    const gltf = useGLTF(`https://x1hz-knoc-qcpv.e2.xano.io${props.pose.modelPath}`)
    // const gltf = useGLTF(databaseURL)
    const copiedScene = useMemo(() => gltf.scene.clone(), [gltf.scene])
    // const { nodes } = useGLTF(databaseURL)
 
    const primitiveRef = useRef()
// console.log(nodes)



    return (<group scale={1} ref={primitiveRef} >
       <mesh  >
        <primitive ref={primitiveRef} position={[0,0,0]} rotation={[0,0,0]} object={copiedScene}/>
        </mesh>
      </group>
    )
  } 